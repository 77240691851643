import "../assets/css/LoginContent.css"
import Header from "./Header"
import Footer from "./Footer"
import LoginContent from "./LoginContent"

function Login() {
  return (
      <div>

        <Header/>
      
        <body>
          <LoginContent/>
        </body>   
      
        <Footer/>
      </div>
  )
}

export default Login